import styled from "styled-components";
import { theme } from "../../styles/theme";

export const SubheadingText = styled.p`
  color: ${theme.colors.Primary03};
  font-weight: 700;
  font-size: 1.1rem;
`;

export const UnderText = styled.p`
  color: ${theme.colors.Primary03};
  font-size: 0.9rem;
`;

export const LineBreak = styled.hr`
  margin-top: 5px;
  color: ${theme.colors.Grey01};
`;

export const AdditionalServicesContainer = styled.div``;

export const AdditionalServicesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4em;
  padding: 1.5em 0;

  a {
    text-decoration: underline;
  }
`;

export const YourPurchaseContainer = styled.div`
  padding: 2em 0;
`;

export const YourPurchaseDetailContainer = styled.div`
  display: grid;
  gap: 0.75em;
  padding: 1.5em 0;
`;

export const YourPurchaseDetail = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const YourPurchaseTitle = styled.p`
  color: ${theme.colors.Primary03};
  width: 100%;
`;

export const OriginalPrice = styled.p`
  color: ${theme.colors.Primary03};
  text-decoration: line-through;
  margin-right: 10px;
`;

export const YourPurchasePrice = styled.p`
  color: ${theme.colors.Primary03};
`;

export const DonationCotainer = styled.div`
  padding: 2em 0;
`;

export const DonationFormContainer = styled.div`
  padding: 1em 0;
  h3 {
    font-weight: 500;
    font-size: 1rem;
    color: ${theme.colors.Grey02};
    margin-bottom: 0.5em;
  }
  & > div {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1.5em;
    @media (max-width: 425px) {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }
  }
`;
